.page-view.login {
  @apply
    relative
    bg-gradient-to-t
    from-primary-blue
    via-primary-blue
    to-primary-lblue
    px-4
    flex
    flex-col
    justify-center
    items-center
    z-10
    overflow-hidden;

  .bg-image {
    @apply
      absolute
      inset-0
      h-full
      w-full
      object-cover
      opacity-10
      -z-2;
  }

  #tsparticles {
    @apply
      absolute
      inset-0
      -z-1
      opacity-25;
  }

  .form-wrapper {
    @apply
      bg-white
      bg-opacity-95
      rounded-xl
      overflow-hidden
      px-4
      pt-2
      pb-4
      shadow-xl
      max-w-lg;

    h1 {
      @apply
        text-center
        uppercase
        font-bold
        tracking-widest
        block
        border-b
        border-dotted
        border-primary-gray
        mb-4
        pb-2
        mt-2;
    }

    p {
      @apply
        text-primary-black
        text-sm
        mb-4;
    }
  }
}